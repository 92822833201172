.transition-container {
  opacity: 1;
  overflow: hidden;
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}


.fast-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  padding-top: 15px;
  padding-bottom: 0px;
  flex-wrap: wrap;

  & .item {
    background: transparent;
    color: black;
    border: 1px solid #d2d0d0;

    img {
      padding-right: 5px;
    }
  }

  & .item:hover {
    color: black;
    background: white;
  }

  & .active {
    color: black;
    background: white;
  }
}


.btn-groups {
  margin-top: 20px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}


.btn {
  background-color: rgb(60, 179, 113);
  font-size: 14px;
  border: none;
  border-radius: 8px;
  color: #ffffff;
}

.btn-add {
  background-color: rgb(60, 179, 113);
}

.btn-filter {
  background-color: #A3195B;
}

.btn-clear {
  background-color: rgb(167 163 165) !important;
}

.btn-cancel {
  background-color: rgb(167 163 165);
}

.not-actual-city {
  background: #f8c212 !important;
}

.active-auction {
  background: rgba(13, 110, 253, 1) !important;
}

.expeditor-order {
  background: #f0f0f0;
  color: #141619;
}

.overdue-orders {
  background: #f58888 !important;
  color: #fff;
}

