.analytics .main-body {
  padding: 40px 0 0 0;
}

.metric {
  padding: 0 20px 0 20px;
}

.analytics-container {
  margin-top: 20px;


  .metrics-row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 24px;

    .metric-card {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      position: relative;

      .metric-label {
        position: absolute;
        top: -30px;
        font-size: 14px;
        font-weight: 400;
        width: 120px;
        text-align: center;
      }

      .metric-value {
        font-size: 24px;
        font-weight: 700;

        .metric-unit {
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.created {
        background-color: #fffacd; // Light yellow
      }

      &.booked {
        background-color: #d0f0c0; // Light green
      }

      &.canceled {
        background-color: #ffcccb; // Light red/pink
      }

      &.processing {
        background-color: #add8e6; // Light blue
      }
    }
    }

  .chart-container {
    margin-top: 40px;
      
    .chart-title {
      padding: 0 20px 0 20px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
    }
  }
}
  
#orderChart {
  width: 100%;
  height: 300px;
}

.metric{
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;


  &_item{
    display: flex;
    padding: 10px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    // flex: 1 0 0;
    border-radius: 16px;
    border: 0.5px solid #E6E0EC;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }

  &_title{
    align-self: stretch;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15px;
  }

  &_number{
    align-self: stretch;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
  }
}



@media screen and (max-width: 600px) {
  .metric {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;

    &_item{
      display: flex;
      padding: 6px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      // flex: 1 0 0;
      border-radius: 16px;
      border: 0.5px solid #E6E0EC;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }

    &_title{
      align-self: stretch;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.15px;
    }

    &_number{
      align-self: stretch;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
    }

    //заказы
    &_item:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    //забронированные
    &_item:nth-child(2) {
      grid-column: 1;
      grid-row: 2;
    }

    //отмененные
    &_item:nth-child(3) {
      grid-column: 1;
      grid-row: 3;
    }

    //Сред время обработки модераций
    &_item:nth-child(4) {
      grid-column: 2;
      grid-row: 1 / span 2;
      height: 100%;
    }

    //Сред время обработки бронирования
    &_item:nth-child(5) {
      grid-column: 2;
      grid-row: 3 / span 2;
    }
  }
}
